import { Grid, Button, Divider } from '@material-ui/core';
import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import { Component } from 'react';
import fire from '../config/fire'
import parse from 'html-react-parser';

class DisplayReply extends Component {

    constructor(props) {
        super(props)

        this.state = {
            replies: []
        }
    }

    componentDidMount() {
        let list = []
        const str = 'Replies/Weekly/' + (this.props.qid);

        fire.database().ref(str).on('value', snapshot => {

            snapshot.forEach(snap => {
                list.push(snap.val());
            })

            this.setState({ replies: list })
        })
    }

    componentWillReceiveProps({ ques }) {
        this.componentDidMount()
    }

    render() {
        return (
            <Grid container style={{ marginLeft: '15px' }}>
                {
                    (this.state.replies).length > 0 ?
                        <Grid item xs={12} className="replies-box">
                            {
                                this.state.replies.map(q => {
                                    return (
                                        <div>
                                            <div key={q.rid} className="replies-div">
                                                <Avatar style={{ marginRight: '15px' }} alt={q.name} src={q.photo || "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"} />
                                                <div>
                                                    <p style={{ color: 'blue' }}>{q.name}</p>
                                                    <p className="caption"><i>Added {new Date(q.rid).toLocaleString("en-IN")}</i></p>
                                                </div>
                                            </div>
                                            <div>
                                                <p id="ans" >{(q.reply).split('\n').map(str => <p>{str}</p>)}</p>
                                            </div>
                                            <Divider />
                                        </div>
                                    )
                                })
                            }
                        </Grid> :
                        <Grid item xs={12} align="center">
                            <p>No Replies Here yet!!</p>
                        </Grid>
                }
            </Grid >
        )
    }
}


export default DisplayReply