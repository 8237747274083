import React from 'react'
import fire from '../../config/fire'
import Swal from "sweetalert2";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Contact from '../Contact'

function getSteps() {
    return ['Question 1'];
}

function BooleanTask12Ques(props) {

    const [state, setState] = React.useState({
        name: props.name,
        email: props.email,
        uid: props.uid,
        answer1: "",
        date: new Date().toLocaleString("en-IN"),
        score: "",
        maxscore: 5,
        comment: '',
        scores: [""],
        quiz: "BooleanTask12"
    })

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleText = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container>
                        <img src="https://i.ibb.co/x1Y1MKV/Task-12.png" />
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Write Deconstruction and Boolean" variant="outlined" name="answer1"
                                onChange={handleText} value={state.answer1} fullWidth multiline rows={3} rowsMax={10} />
                        </Grid>
                    </Grid>
                )
        }
    }

    const handleSubmit = () => {

        if (props.fill === true) {
            fire
                .database()
                .ref("BooleanTask12")
                .child(props.uid)
                .set(state)

            Swal.fire({
                icon: "success",
                title: "Awesome! You've completed Boolean Task-12",
            }).then(res => {
                props.rerenderParentCallback();
            })
        }
    };

    if (props.attempt)
        return (
            <div>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <div>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >Back</Button>
                                        {activeStep === steps.length - 1 ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >Finish</Button>
                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >Next/Skip</Button>
                                        }
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Contact />
            </div>
        )
    else
        handleSubmit()
}

export default BooleanTask12Ques