import React from 'react'
import Header from './Header'
import { Component } from 'react';
import fire from '../config/fire'
import Course from './Course'
import NormalLogin from '../NormalLogin'
import { Button, Grid } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            users: []
        }
    }

    componentDidMount() {
        let list = []
        fire.database().ref('Permissions/Weekly/uid').once('value', snapshot => {

            this.setState({ users: Object.keys(snapshot.val()) })
        })

        fire.auth().onAuthStateChanged(user => {
            if (user) {
                var t = {
                    uid: user.uid,
                    name: user.displayName,
                    email: user.email,
                    pic: user.photoURL
                }
                console.log(t)
                fire.database().ref('users/' + t.uid).set(t).catch(error => {
                    console.log(error.message)
                });
            }
        })
    }

    render() {
        return (
            <div>
                <Header />
                <Grid item xs={12}>
                    {
                        (this.props.uid === "w3u78z3dj9ShUtM4DUSRwunUlTw2" || this.props.uid === "ERW6W79Tc2W4X505adIHBGKRH7t1" || this.props.uid === "kSwelLNvPSauO9yjZsQZv19uqcH2") ?
                            <div style={{ padding: '15px', marginBottom: '50px' }}>
                                <Button
                                    style={{ float: 'right' }}
                                    variant="contained"
                                    color="primary"
                                    href="/admin"
                                    startIcon={<ExitToAppIcon />}
                                >
                                    Go to admin portal</Button>
                            </div>
                            :
                            ''
                    }
                </Grid>
                {
                    this.props.uid ? this.state.users.includes(this.props.uid) ? <Course uid={this.props.uid} /> : <div align="center center" style={{ padding: '20px' }}><h2>No Permission! Please reach out to admin to get yourself enrolled.<br />Thank You!</h2></div> : <NormalLogin />
                }
            </div>
        )
    }
};

export default Home