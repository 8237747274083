import { Grid, Button, Divider } from '@material-ui/core';
import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import { Component } from 'react';
import ReplyRoundedIcon from '@material-ui/icons/ReplyRounded';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import fire from '../config/fire'
import Reply from './Reply'
import parse from 'html-react-parser';

class DisplayQues extends Component {

    constructor(props) {
        super(props)

        this.state = {
            ques: [],
            dis: false
        }

        this.handleDisp = this.handleDisp.bind(this);
    }

    componentDidMount() {
        let list = []
        fire.database().ref('Questions/Weekly').once('value', snapshot => {

            snapshot.forEach(snap => {
                list.push(snap.val());
            })

            this.setState({ ques: list })
        })
    }

    handleDisp = (id) => {
        console.log(id)
        var el = document.getElementById(id)
        if (el) {
            if (el.style.display === 'block')
                el.style.display = 'none'
            else
                el.style.display = 'block'
        }
    }

    componentWillReceiveProps({ ques }) {
        this.componentDidMount()
    }

    render() {
        return (
            <Grid container style={{ marginLeft: '15px' }}>
                <Grid item xs={12} md={9} className="ques-box">
                    {
                        this.state.ques.map((q, index) => {
                            return (
                                <div>
                                    <div key={index} className="ques-div">
                                        <Avatar style={{ marginRight: '15px' }} alt={q.name} src={q.photo || "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"} />
                                        <div>
                                            <p><b>{parse(q.question)}</b></p>
                                            <p className="caption">Posted by: {q.name.substr(0, q.name.indexOf(' '))}</p>
                                        </div>
                                    </div>
                                    <div align="right">
                                        <Button style={{ marginRight: '10px' }} onClick={() => this.handleDisp(q.qid)}><QuestionAnswerIcon fontSize="small" style={{ color: '#707070', cursor: 'pointer' }} /><span style={{ marginLeft: '5px' }}>Replies</span></Button>
                                        <Button onClick={() => this.handleDisp(q.qid)}><ReplyRoundedIcon fontSize="small" style={{ color: '#707070', cursor: 'pointer' }} /><span style={{ marginLeft: '5px' }}>Reply</span></Button>
                                    </div>
                                    <Grid id={q.qid} style={{ display: 'none' }} item xs={12}>
                                        <Reply user={this.props.user} qid={q.qid} />
                                    </Grid>
                                    <Divider />
                                </div>
                            )
                        })
                    }
                </Grid>
            </Grid >
        )
    }
}


export default DisplayQues