import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


export default class T2 extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card>
                <div className="Tmon">
                    <CardContent>
                        Recruting Monk- It is the centre for all talent acquisition professionals, one must attend this series in their career span. The insights and experiences shared are way high and provides the real time scenarios. Must have for the TA professionals!!
                </CardContent>
                </div>
                <div className="names">
                    <div>
                        <img src="/images/Piyush.jpeg" alt="Piyush" />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <h3>Piyush Naik</h3>
                        <p style={{ color: 'blue' }}>DigiValet Inc.</p>
                    </div>
                </div>
            </Card>
        );
    }
}