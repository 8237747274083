import { Button, Grid } from '@material-ui/core';
import React from 'react'
import { Component } from 'react';
import Loader from './Loader'
import Cards from './Card'
import './fe.css'
import Header from '../Header'
import DeskFooter from './DeskFooter'
import CountUp from 'react-countup';
import Blog from '../Blog/Blog'

class LandingPage extends Component {

    render() {
        return (
            <div>
                <Header />
                <Grid container className='land'>
                    <Grid item md={5} className='land-first'>
                        <p>THERE IS A DISCONNECT BETWEEN DEGREES AND JOBS.<br /> <b style={{ color: '#b32800' }}>MONK ACADEMY</b> IS HERE TO FILL THAT GAP!</p>
                    </Grid>
                    <Grid item md={4}>
                        <Loader />
                    </Grid>
                </Grid>
                <Grid container className='cards-cont'>
                    <Grid item md={3}>
                        <Cards title='For Experience Professionals' subtitle='1+ years of experience' im='https://assets.scaler.com/assets/scaler_branding/svg/scaler-academy-94ac1fb3186ca3dd2d7a8118102b1b565df1486ec9824bc84f3a98c32b131369.svg.gz' />
                    </Grid>
                    <Grid item md={3}>
                        <Cards title='For College Students' subtitle='Current College and University Students' im='https://assets.scaler.com/assets/scaler_branding/svg/scaler-edge-a3444475f1d90d61d3dcbd99561a09e5da2c820e1f8d55de61f7fc5e7c46ae25.svg.gz' />
                    </Grid>
                    <Grid item md={3}>
                        <Cards title='For Agencies' subtitle='Hire with us' im='https://assets.scaler.com/assets/scaler_branding/svg/scaler-academy-94ac1fb3186ca3dd2d7a8118102b1b565df1486ec9824bc84f3a98c32b131369.svg.gz' />
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: '#b32800' }}>
                    <Grid item xs={12} md={7} style={{ paddingLeft: '20px' }}>
                        <h2 class="elw">Want to hire Technology<br />Recruiters?</h2>
                        <br />
                        <br />
                        <Button size='large' id='talk' variant="contained" href="https://wa.me/message/XJKMT6EAGWRHI1" target="_blank">Talk to us</Button>
                    </Grid>
                    <Grid item id='butt' xs={12} md={3}>
                        <img src="https://recruitingmonk.com//wp-content/uploads/2020/09/loader-gif.gif" />
                    </Grid>
                </Grid>
                <Grid container className='land'>
                    <Grid item md={12} align='center' className='land-first'>
                        <p>Below are a few <b style={{ color: '#b32800' }}>snapshots</b></p>
                    </Grid>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Grid item md={5}>
                            <a href='https://www.linkedin.com/feed/update/urn:li:activity:6655367871155269633?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A6655367871155269633%2C6655375549189447680%29' target="_blank"><img width="100%" height="230" src="https://recruitingmonk.com//wp-content/uploads/2020/06/comment1.jpeg" /></a>
                            <a href='https://www.linkedin.com/feed/update/urn:li:activity:6655367871155269633/?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A6655367871155269633%2C6655385179890245632%29' target="_blank"><img width="100%" height="217" src="https://recruitingmonk.com//wp-content/uploads/2020/06/comment2.jpeg" /></a>
                            <a href='https://www.linkedin.com/pulse/practice-achyut-menon-ak-/' target="_blank"><img width="100%" height="648" src="https://recruitingmonk.com//wp-content/uploads/2020/06/comment3.jpeg" /></a>
                            <a href='https://www.linkedin.com/feed/update/urn:li:activity:6657150515127910400/?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A6657150515127910400%2C6657908675371503616%29' target="_blank"><img width="100%" height="136" src="https://recruitingmonk.com//wp-content/uploads/2020/06/comment4.jpeg" /></a>
                        </Grid>
                        <Grid item md={5}>
                            <a href='https://www.linkedin.com/pulse/advanced-sourcing-swathy-sanjeevi/' target="_blank"><img width="100%" height="637" src="https://recruitingmonk.com//wp-content/uploads/2020/06/comment5.jpeg" /></a>
                            <a href='linkedin.com/posts/adisharma26_tech-recruiters-advance-sourcing-i-activity-6653622743663157248-8ylC/' target="_blank"><img width="100%" height="406" src="https://recruitingmonk.com//wp-content/uploads/2020/06/comment6.jpeg" /></a>
                            <a href='https://www.linkedin.com/feed/update/urn:li:activity:6667427834430140416/?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A6667427834430140416%2C6667516609491554304%29' target="_blank"><img width="100%" height="189" src="https://recruitingmonk.com//wp-content/uploads/2020/06/comment7.jpeg" /></a>
                        </Grid>
                    </div>
                </Grid>
                <Grid container style={{ backgroundColor: '#f3f9f9' }}>
                    <Grid item xs={12} md={7} style={{ paddingLeft: '20px' }}>
                        <h2 class="elw2">Want to assess your tech<br />recruiting skills?</h2>
                        <br />
                        <br />
                        <Button size='large' id='talk2' variant="contained" href="https://quiz.recruitingmonk.com/" target="_blank">Visit Now</Button>
                    </Grid>
                    <Grid item id='butt' xs={12} md={3}>
                        <img src="https://recruitingmonk.com//wp-content/uploads/2020/09/loader-gif.gif" />
                    </Grid>
                </Grid>
                <Grid container className='land2'>
                    <Grid item md={12} align='center' className='land-first'>
                        <p>Our Trainees work at reputed <b style={{ color: '#b32800' }}>companies</b></p>
                    </Grid>
                    <Grid item md={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Grid item md={2}>
                            <img src="/images/1.png" />
                        </Grid>
                        <Grid item md={2}>
                            <img src="/images/2.png" />
                        </Grid>
                        <Grid item md={2}>
                            <img src="/images/3.png" />
                        </Grid>
                        <Grid item md={2}>
                            <img src="/images/4.png" />
                        </Grid>
                    </Grid>
                    <Grid item md={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Grid item md={2}>
                            <img src="/images/5.png" />
                        </Grid>
                        <Grid item md={2}>
                            <img src="/images/6.png" />
                        </Grid>
                        <Grid item md={2}>
                            <img src="/images/7.png" />
                        </Grid>
                        <Grid item md={2}>
                            <img src="/images/8.png" />
                        </Grid>
                    </Grid>
                    <Grid item md={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Grid item md={2}>
                            <img src="/images/9.png" />
                        </Grid>
                        <Grid item md={2}>
                            <img src="/images/10.png" />
                        </Grid>
                        <Grid item md={2}>
                            <img src="/images/11.png" />
                        </Grid>
                        <Grid item md={2}>
                            <img src="/images/12.png" />
                        </Grid>
                    </Grid>
                    <Grid item md={12} align='center' className='pp'>
                        <p>and 1000+ employer partners</p>
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: '#b32800', textAlign: 'center' }}>
                    <Grid item md={12} align='center' className='land-first2'>
                        <p style={{ color: '#fff' }}><b style={{ color: '#f99c38' }}>Monk</b> impact so far</p>
                    </Grid>
                    <Grid item md={3} style={{ paddingBottom: '20px' }}>
                        <h2 class="elw">Recruiters</h2>
                        <CountUp style={{ color: '#f99c38', fontSize: '48px', fontWeight: '500' }} delay={2} start={0}
                            end={11000}
                            duration={5} /><span style={{ color: '#f99c38', fontSize: '42px', fontWeight: '500' }}>+</span>
                    </Grid>
                    <Grid item md={3} style={{ paddingBottom: '20px' }}>
                        <h2 class="elw">Companies</h2>
                        <CountUp style={{ color: '#f99c38', fontSize: '48px', fontWeight: '500' }} delay={2} start={0}
                            end={1000}
                            duration={5} /><span style={{ color: '#f99c38', fontSize: '42px', fontWeight: '500' }}>+</span>
                    </Grid>
                    <Grid item md={3} style={{ paddingBottom: '20px' }}>
                        <h2 class="elw">Sessions</h2>
                        <CountUp style={{ color: '#f99c38', fontSize: '48px', fontWeight: '500' }} delay={2} start={0}
                            end={600}
                            duration={5} /><span style={{ color: '#f99c38', fontSize: '42px', fontWeight: '500' }}>+</span>
                    </Grid>
                    <Grid item md={3} style={{ paddingBottom: '20px' }}>
                        <h2 class="elw">Community</h2>
                        <CountUp style={{ color: '#f99c38', fontSize: '48px', fontWeight: '500' }} delay={2} start={0}
                            end={3000}
                            duration={5} /><span style={{ color: '#f99c38', fontSize: '42px', fontWeight: '500' }}>+</span>
                    </Grid>
                </Grid>
                <Blog />
                <DeskFooter />
            </div>
        )
    }
}

export default LandingPage