import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


export default class T3 extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card>
                <div className="Tmon">
                    <CardContent>
                        The one and only platform for tech recruiters to test their metal. I strongly recommend every Recruiter to take up this challenge!
                </CardContent>
                </div>
                <div className="names">
                    <div>
                        <img src="/images/Varun.jpeg" alt="Varun" />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <h3>Varun Kapadia</h3>
                        <p style={{ color: 'blue' }}>PayPal</p>
                    </div>
                </div>
            </Card>
        );
    }
}