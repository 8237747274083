import React, { Component } from 'react';
import {
    Button,
    Dialog, DialogContent
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

class Popup extends Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false
        }
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    componentDidMount() {
        console.log(this.props)
    }

    render() {
        return (
            <div align="center" >
                <Button variant='outlined' onClick={this.handleOpen}>View Resume {this.props.option}</Button>
                <Dialog open={this.state.open} onClose={this.handleClose} className='pop-ques' fullWidth>
                    <Button style={{ alignSelf: 'flex-end', width: '50px', height: '50px' }} onClick={this.handleClose}><CancelIcon /></Button>
                    <DialogContent>
                        <iframe id="emp" src={"/pdf/" + this.props.option + ".pdf"} type="application/pdf" />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default Popup;