import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import fire from '../config/fire'
import { Grid, Hidden } from '@material-ui/core';
import Swal from 'sweetalert2'
import DisplayReply from './DisplayReply';


class Reply extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reply: "",
            dis: true,
            rid: new Date().getTime(),
            uid: this.props.user.uid,
            photo: this.props.user.photoURL,
            name: this.props.user.displayName,
            qid: this.props.qid
        };
    }

    componentDidMount() {
        console.log(this.props)
    }

    handleReply = e => {

        if (e.target.value === "") {
            this.setState({
                dis: true,
                reply: ""
            });
        }
        else {
            this.setState({
                reply: e.target.value,
                dis: false
            });
        }
    };

    submitReply = e => {

        fire
            .database()
            .ref("Replies/Weekly/" + this.state.qid)
            .child(this.state.rid)
            .set(this.state);
        
        Swal.fire(
            'Great!',
            'Your Reply is Posted!',
            'success'
        )

        this.setState({
            reply: "",
            dis: true,
            rid: new Date().getTime(),
            uid: this.props.user.uid,
            photo: this.props.user.photoURL,
            name: this.props.user.displayName,
            qid: this.props.qid
        });

    };

    render() {

        return (
            <Grid container>
                <Grid item xs={12} sm={9} md={9}>
                    <div className="Reply-container" style={{ display: this.state.display }}>
                        <TextField fullWidth multiline value={this.state.reply}
                            onChange={this.handleReply}
                            rowsMax={5} className="Reply-field" rows={2}
                            label="Reply" placeholder="Type Your Reply Here"
                            variant="outlined" />
                    </div>
                </Grid>
                <Hidden only={['lg', 'md', 'sm', 'xl']}><Grid item xs={9}></Grid></Hidden>
                <Grid item xs={3} sm={3} md={3}>
                    <div style={{ paddingTop: '20px' }} className="Reply-container-buttons">
                        <Button size="small" variant="contained" className="Submit-Reply"
                            disabled={this.state.dis}
                            onClick={this.submitReply}
                        >Reply</Button>
                    </div>
                </Grid>
                {
                    this.state.qid ? <DisplayReply ques={this.state.rid} qid={this.state.qid} /> : '' 
                }
            </Grid>
        );
    }
}

export default Reply;