const videos = [
    {
        "id": 539324609,
        "name": "What is Recruitment?",
        "about": "An overview of Recruitment as a practice."
    },
    {
        "id": 539324800,
        "name": "Industry Overview",
        "about": "How does the IT industry work, a quick glimpse."
    },
    {
        "id": 539324940,
        "name": "Recruitment Life Cycle - Intro to JD",
        "about": "The first step in Recruitment is understanding Job Descriptions, let's get a quick sneak peek about this."
    },
    {
        "id": 539325095,
        "name": "Demystifying tech for Recruiters",
        "about": "Recruiters should have some basic tech know how’s to hold good conversations with candidates & in the entire recruiting cycle becomes easy for IT hiring."
    },
    {
        "id": 539325245,
        "name": "Introduction to SDLC",
        "about": "In this video, you will understand how a software development cycle works beginning from understanding the requirements to delivering the software."
    },
    {
        "id": 539325539,
        "name": "JD Deconstruction-I",
        "about": "How will you deconstruct a Job Description, why understanding the JD in the right sense is crucial."
    },
    {
        "id": 539325825,
        "name": "JD Terminologies",
        "about": "Different Kinds of JD terminologies which you will find in job descriptions."
    },
    {
        "id": 539326154,
        "name": "Product vs Service",
        "about": "What is this Product vs Service? Which companies are product & which are service, understand the differences between the two."
    },
    {
        "id": 539326441,
        "name": "Recruitment Life Cycle",
        "about": "Understanding how Recruitment process works & the flow of it."
    },
    {
        "id": 539326689,
        "name": "Startups",
        "about": "What is this term startup all about?"
    },
    {
        "id": 539326985,
        "name": "Tips for JD Deconstruction",
        "about": "Let’s learn how do we deconstruct a given Job description."
    },
    {
        "id": 539327164,
        "name": "Types of Hiring",
        "about": "What are the different types of hirings out there?"
    },
    {
        "id": 539327379,
        "name": "Types of Product Firms",
        "about": "What are the different kinds of business models out there, a quick overview of the different types."
    },
    {
        "id": 539327545,
        "name": "Understanding Developer role for Recruiters",
        "about": "This is a detailed video on the basics of SDLC.<br/> <ul><li>Language- OS</li><li>Database</li><li>Methodology</li><li>Framework</li><li>App server</li><li>IDE</li><li>Webservice</li></ul>"
    },
    {
        "id": 541149629,
        "name": "JD Deconstruction - II",
        "about": "Now your JD understanding will be better after running through the tech terminologies."
    },
    {
        "id": 541148721,
        "name": "What is Boolean",
        "about": "Boolean is lingo to talk to search engines, learn the fundamentals behind this lingo."
    },
    {
        "id": 541150972,
        "name": "Task-1 Explanation",
        "about": "Explanation about Task 1."
    },
    {
        "id": 541152257,
        "name": "Technology-2 Infrastructure/Cloud",
        "about": "More about technology, infrastructure or cloud."
    },
    {
        "id": 541150396,
        "name": "Microservice Architecture",
        "about": "What is architecture and the different types."
    },
    {
        "id": 541150759,
        "name": "Other General Technologies",
        "about": "General tech stacks which support in development."
    },
    {
        "id": 541151405,
        "name": "Task-2 Explanation",
        "about": "Explanation of task number 2."
    },
    {
        "id": 541151606,
        "name": "Task-3 Explanation",
        "about": "Explanation of task number 3."
    },
    {
        "id": 552111000,
        "name": "Learning Tech Clusters",
        "about": "Learning Tech Clusters"
    },
    {
        "id": 552118315,
        "name": "Task-4 Explanation",
        "about": "Explanation of task number 4."
    },
    {
        "id": 552125771,
        "name": "Task-5 Explanation",
        "about": "Explanation of task number 5."
    },
    {
        "id": 552098249,
        "name": "Task-6 Explanation",
        "about": "Explanation of task number 6."
    },
    {
        "id": 552132723,
        "name": "Task-7 Explanation",
        "about": "Explanation of task number 7."
    },
    {
        "id": 552134052,
        "name": "Task-8 Explanation",
        "about": "Explanation of task number 8."
    },
    {
        "id": 552136307,
        "name": "UI Designer & UI Developer Role",
        "about": "UI Designer & UI Developer Role."
    },
    {
        "id": 552137865,
        "name": "Task-9 UI Developer",
        "about": "Explanation of task number 9."
    },
    {
        "id": 552139255,
        "name": "Task-10 .Net Developer",
        "about": "Explanation of task number 10."
    },
    {
        "id": 552145480,
        "name": "Task-11 Java Developer",
        "about": "Explanation of task number 11."
    },
    {
        "id": 552147194,
        "name": "Task-12 Java Developer",
        "about": "Explanation of task number 12."
    },
    {
        "id": 552213019,
        "name": "Introduction to Naukri",
        "about": "Introduction to Naukri."
    },
    {
        "id": 552214727,
        "name": "Screening or Probing",
        "about": "Screening or Probing."
    },
    {
        "id": 554929328,
        "name": "Boolean Mistakes",
        "about": "Mistakes people do while using Boolean."
    },
    {
        "id": 554929010,
        "name": "Testing Role",
        "about": "Testing Role"
    },
    {
        "id": 554937838,
        "name": "Task-14 Explanation",
        "about": "Explanation of task number 14."
    },
    {
        "id": 554934065,
        "name": "Tweaking Boolean - In Ref to Data",
        "about": "Tweaking Boolean - In Ref to Data."
    },
    {
        "id": 555474550,
        "name": "Candidate Pitch",
        "about": "Candidate Pitch."
    },
    {
        "id": 555474683,
        "name": "DevOps Engineer & Boolean Tweaks",
        "about": "DevOps Engineer & Boolean Tweaks."
    },
    {
        "id": 555474782,
        "name": "Followup Strategy",
        "about": "Followup Strategy."
    }
];

export default videos