import React, { Component } from 'react'
import fire from '../config/fire'
import { Button, Grid } from '@material-ui/core';
import { InputLabel, NativeSelect } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Swal from "sweetalert2";
import NewDrawing from './New Drawing'
import moment from 'moment'

class NewAdmin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            access: [],
            uids:[]
        }
    }

    mount(quiz) {

            fire.database().ref(quiz).once('value', snapshot => {
                let list = []

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })

                let ans = list.sort(function (a, b) {

                    return moment(b.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") - moment(a.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x")
                });

                this.setState({ detail: ans })
            })
    }

    componentDidMount() {
        let li = []
        fire.database().ref('users').once('value', snapshot => {

            snapshot.forEach(snap => {
                li.push(snap.val());
            })

            this.setState({ users: li })
        })

        let list = []
        fire.database().ref('Permissions/Weekly/uid').once('value', snapshot => {

            this.setState({ uids: Object.keys(snapshot.val()) })
            console.log(this.state.uids)

            snapshot.forEach(snap => {
                list.push(snap.val());
            })

            this.setState({ access: list })
        })
    }

    handleScore = (id, q) => {
        console.log(id, q)

        let val = document.getElementById(id).value
        console.log(val)
        fire.database().ref(q)
            .child(id).update({
                'score': val
            })
    }

    rerenderParentCallback = () => {
        this.componentDidMount()
    }

    render() {
        return (
            <div>
                <Grid container><Grid item xs={12}><p></p></Grid></Grid>
                <Grid container>
                    <Grid item xs={12} style={{ overflowX: 'auto', marginTop: '20px', padding: '10px' }}>
                        <table className="admin-table">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Check Tasks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.users.map(i => {
                                    return (
                                        <tr key={i.uid}>
                                            <td>{i.name}</td>
                                            <td>{i.email}</td>
                                            <td>{this.state.uids.includes(i.uid) ? 'Active' : 'Not Active'}</td>
                                            <td>{this.state.uids.includes(i.uid) ? <NewDrawing rerenderParentCallback={this.rerenderParentCallback} uid={i.uid} /> : 'Inactive'}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default NewAdmin
