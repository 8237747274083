import React, { Component } from 'react';
import fire from '../../config/fire';
import { Grid } from '@material-ui/core';
import Loader from '../../Loader'
import { withRouter } from "react-router-dom"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Sidebar from '../Sidebar'
import Header from '../Header'
import Like from './Like'
import parse from 'html-react-parser';

function Likes(props) {
    return (
        <Card style={{ backgroundColor: '#fff', marginTop: '30px', marginBottom: '30px' }}>
            <CardContent className="post-title">
                <span>YOU MAY ALSO LIKE</span>
            </CardContent>
            <CardContent>
                <Like title={props.props} />
            </CardContent>
        </Card>
    );
}

function Author() {

    return (
        <Card style={{ backgroundColor: '#fff', marginTop: '30px', marginBottom: '30px' }}>
            <CardContent className="post-title">
                <span>ABOUT THE AUTHOR</span>
            </CardContent>
            <CardContent>
                <div className="author-image">
                    <img src="https://recruitingmonk.com/wp-content/uploads/2019/10/0.jpeg" alt="Ashfaq Ahmed" />
                </div>
                <div>
                    <p className="author-p">Engineer by Chance, Recruiter by Choice. The urge to impact lives of more Recruiters made me create RecruitingMonk. And to quench my urge, I keep helping recruiters via workshops, coaching & more. Having trained 3000 recruiters, I aim to impact lives of 10000 recruiters shortly.</p>
                </div>
            </CardContent>
        </Card>
    );
}

class BlogContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            infos: []
        }
    }

    componentDidMount() {

        let list = []
        fire.database().ref('Blog').once('value', snapshot => {

            snapshot.forEach(snap => {
                list.push(snap.val());
            })

            this.setState({ infos: list })
        })
    }

    render() {
        const title = this.props.match.params.title;

        return (
            <div>
                <Loader />
                <Header />
                <Grid container className="blog-container">
                    <Loader />
                    {
                        this.state.infos.map((info) => {
                            if (info.title === title)
                                return (
                                    <Grid key={info.title} item xs={12} md={8} style={{ boxShadow: "0 2px 0 0 #ECF1F2" }}>
                                        <div className="blog-content">
                                            <span><i>{info.date.substr(info.date.indexOf(' ') + 1)}</i> . {info.category}</span>
                                            <h2>{info.title}</h2>
                                            {
                                                info.image ? <img width="100%" className="blog-img" src={info.image} /> : ''
                                            }
                                            <p id="content">{parse(info.content)}</p>
                                        </div>
                                        <Author />
                                        <Likes props={title} />
                                    </Grid>
                                )
                        })
                    }
                </Grid>
            </div>
        )
    }
}

export default withRouter(BlogContent);