import React, { useContext } from 'react'
import { UserContext } from "../Providers/UserProvider";
import { auth } from "../config/fire";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Grid, Avatar, Button } from '@material-ui/core';
import Login from '../Login'


const Header = () => {
    const user = useContext(UserContext);

    return (
        <div>
            {
                user ?
                    <div>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container style={{ display: 'inline-flex', backgroundColor: '#f4e9a2', height: '73px' }}>
                                    <Grid item xs={6} md={8} style={{ display: 'flex', alignSelf: 'center' }}>
                                        <h3 className="name" style={{ float: 'left', color: '#b32800' }}>Welcome {user.displayName.substr(0, user.displayName.indexOf(' '))}</h3>
                                    </Grid>
                                    <Grid item xs={6} md={4} className="logout" style={{ alignSelf: 'center' }}>
                                        <div style={{ float: 'right', display: 'inline-flex' }}>
                                            <Avatar style={{ marginRight: '15px' }} alt={user.displayName} src={user.photoURL || "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"} />
                                            <Button onClick={() => { auth.signOut(); window.location.href = "/" }} className="button-default" variant="contained" color="primary"><ExitToAppIcon />Logout</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div> :
                    <Grid container style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                        <Grid item xs={12}>
                            <Grid container style={{ display: 'inline-flex' }}>
                                <Grid item xs={8} id="mob" style={{ display: 'flex' }}>
                                    <img alt="logo" src="/images/logo.png" className="logo" />
                                </Grid>
                                <Grid item xs={4} className="logout" style={{ alignSelf: 'center' }}>
                                    <div style={{ float: 'right', display: 'inline-flex' }}>
                                        <Login />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            }
        </div>
    )
}

export default Header