const tasks = [
    {
        "id": 1,
        "name": "task1"
    },
    {
        "id": 2,
        "name": "quiz1"
    },
    {
        "id": 3,
        "name": "quiz2"
    },
    {
        "id": 4,
        "name": "quiz3"
    },
    {
        "id": 5,
        "name": "BooleanTask1"
    },
    {
        "id": 6,
        "name": "BooleanTask2"
    },
    {
        "id": 7,
        "name": "BooleanTask3"
    },
    {
        "id": 8,
        "name": "BooleanTask4"
    },
    {
        "id": 9,
        "name": "BooleanTask5"
    },
    {
        "id": 10,
        "name": "BooleanTask6"
    },
    {
        "id": 11,
        "name": "BooleanTask7"
    },
    {
        "id": 12,
        "name": "BooleanTask8"
    },
    {
        "id": 13,
        "name": "BooleanTask9"
    },
    {
        "id": 14,
        "name": "BooleanTask10"
    },
    {
        "id": 15,
        "name": "BooleanTask11"
    },
    {
        "id": 16,
        "name": "BooleanTask12"
    },
    {
        "id": 17,
        "name": "ProbingTask1"
    },
    {
        "id": 18,
        "name": "batch"
    }
];

export default tasks