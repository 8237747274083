import React, { Component } from 'react'
import fire from '../config/fire'
import { Button, Grid } from '@material-ui/core';
import Swal from "sweetalert2";

class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            access: [],
            uids: []
        }
    }

    componentDidMount() {
        let list = []
        fire.database().ref('Permissions/Weekly/uid').once('value', snapshot => {

            this.setState({ uids: Object.keys(snapshot.val()) })
            console.log(this.state.uids)

            snapshot.forEach(snap => {
                list.push(snap.val());
            })

            this.setState({ access: list })
        })

        let li = []
        fire.database().ref('users').once('value', snapshot => {

            snapshot.forEach(snap => {
                li.push(snap.val());
            })

            this.setState({ users: li })
        })
    }

    handleAccess = (id) => {
        console.log(id)

        fire.database().ref('Permissions/Weekly/uid')
            .child(id).update({
                'date': new Date().toISOString(),
                'uid': id
            })

        Swal.fire({
            icon: "success",
            title: 'Success'
        });

        this.componentDidMount()
    }

    handleRevoke = (id) => {
        console.log(id)

        fire.database().ref('Permissions/Weekly/uid')
            .child(id).remove()

        Swal.fire({
            icon: "success",
            title: 'Success'
        });

        this.componentDidMount()
    }

    render() {
        return (
            <div>
                <Grid container><Grid item xs={12}><p></p></Grid></Grid>
                <Grid container className="top">
                    <Grid item xs={6} sm={4} md={2} style={{ padding: '20px' }}>
                        <p><b>Total Users: {this.state.users.length}</b></p>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{ overflowX: 'auto', marginTop: '20px', padding: '10px' }}>
                        <table className="admin-table">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Time on Portal</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.users.map(i => {
                                    return (
                                        <tr key={i.uid}>
                                            <td>
                                                {this.state.access.map(data => {
                                                    if (i.uid === data.uid)
                                                        return (
                                                            Math.ceil(Math.abs((new Date().getTime()) - new Date(data.date).getTime()) / (1000 * 60 * 60 * 24))
                                                        )
                                                })} days
                                            </td>
                                            <td>{i.name}</td>
                                            <td>{i.email}</td>
                                            <td>{this.state.uids.includes(i.uid) ? 'Given' : 'Not Given'}</td>
                                            <td>
                                                {this.state.uids.includes(i.uid) ?
                                                    <Grid container style={{ justifyContent: 'center' }}>
                                                        <Grid item style={{ justifyContent: 'center' }}>
                                                            <Button id={i.uid} onClick={this.handleRevoke.bind(this, i.uid)} color="primary" variant="outlined">Revoke Access</Button>
                                                        </Grid>
                                                    </Grid> :
                                                    <Grid container style={{ justifyContent: 'center' }}>
                                                        <Grid item style={{ justifyContent: 'center' }}>
                                                            <Button id={i.uid} onClick={this.handleAccess.bind(this, i.uid)} color="primary" variant="outlined">Give Access</Button>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default Users