import React from 'react'
import fire from '../../config/fire'
import Swal from "sweetalert2";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Contact from '../Contact'

function getSteps() {
    return ['Question 1', 'Question 2', 'Question 3', 'Question 4', 'Question 5', 'Question 6', 'Question 7', 'Question 8', 'Question 9', 'Question 10'];
}

function Quiz3Ques(props) {

    const [state, setState] = React.useState({
        name: props.name,
        email: props.email,
        uid: props.uid,
        answer1: "",
        answer2: "",
        answer3: "",
        answer4: "",
        answer5: "",
        answer6: "",
        answer7: "",
        answer8: "",
        answer9: "",
        answer10: "",
        date: new Date().toLocaleString("en-IN"),
        score: "",
        maxscore: 10,
        comment: '',
        scores: ["", "", "", "", "", "", "", "", "", ""],
        quiz: "quiz3"
    })

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleText = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container>
                        <Typography>List some build tools?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer1"
                                onChange={handleText} value={state.answer1} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container>
                        <Typography>Which of the below is not a version control tool?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer2" value={state.answer2} onChange={handleText}>
                                    <FormControlLabel value="Github" control={<Radio />} label="Github" />
                                    <FormControlLabel value="SVN" control={<Radio />} label="SVN" />
                                    <FormControlLabel value="GIT" control={<Radio />} label="GIT" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 2:
                return (
                    <Grid container>
                        <Typography> Choose from the list below, which technologies are non mandatory for Backend development ? (multiple choice)</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer3" value={state.answer3} onChange={handleText}>
                                    <FormControlLabel value="Struts" control={<Radio />} label="Struts" />
                                    <FormControlLabel value="OOPS" control={<Radio />} label="OOPS" />
                                    <FormControlLabel value="Tomcat" control={<Radio />} label="Tomcat" />
                                    <FormControlLabel value="Mongo" control={<Radio />} label="Mongo" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                )
            case 3:
                return (
                    <Grid container>
                        <Typography>Pick odd one out</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer4" value={state.answer4} onChange={handleText}>
                                    <FormControlLabel value="Cassandra" control={<Radio />} label="Cassandra" />
                                    <FormControlLabel value="Postgre" control={<Radio />} label="Postgre" />
                                    <FormControlLabel value="Mongo" control={<Radio />} label="Mongo" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 4:
                return (
                    <Grid container>
                        <Typography>Which is not an IDE?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer1" value={state.answer1} onChange={handleText}>
                                    <FormControlLabel value="Visual Studio" control={<Radio />} label="Visual Studio" />
                                    <FormControlLabel value="Agile" control={<Radio />} label="Agile" />
                                    <FormControlLabel value="Eclipse" control={<Radio />} label="Eclipse" />
                                    <FormControlLabel value="Android Studio" control={<Radio />} label="Android Studio" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 5:
                return (
                    <Grid container>
                        <Typography>What is Microservice? List some microservice technologies</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer6"
                                onChange={handleText} value={state.answer6} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
            case 6:
                return (
                    <Grid container>
                        <Typography>Pick odd one out</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer7" value={state.answer7} onChange={handleText}>
                                    <FormControlLabel value="Hibernate" control={<Radio />} label="Hibernate" />
                                    <FormControlLabel value="Entity" control={<Radio />} label="Entity" />
                                    <FormControlLabel value="JPA" control={<Radio />} label="JPA" />
                                    <FormControlLabel value="Mybatis" control={<Radio />} label="Mybatis" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 7:
                return (
                    <Grid container>
                        <Typography>List some data formats, which is actually non mandatory for backend technologies?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer8"
                                onChange={handleText} value={state.answer8} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
            case 8:
                return (
                    <Grid container>
                        <Typography>Which of the below JavaScript framework is used for Backend development?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer9" value={state.answer9} onChange={handleText}>
                                    <FormControlLabel value="AngularJS" control={<Radio />} label="AngularJS" />
                                    <FormControlLabel value="ChartJS" control={<Radio />} label="ChartJS" />
                                    <FormControlLabel value="NodeJS" control={<Radio />} label="NodeJS" />
                                    <FormControlLabel value="ReactJS" control={<Radio />} label="ReactJS" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 9:
                return (
                    <Grid container>
                        <Typography>List a few cloud technologies?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer10"
                                onChange={handleText} value={state.answer10} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
        }
    }

    const handleSubmit = () => {

        if (props.fill === true) {
            fire
                .database()
                .ref("quiz3")
                .child(props.uid)
                .set(state)

            Swal.fire({
                icon: "success",
                title: "Awesome! You've completed Quiz-3",
            }).then(res => {
                props.rerenderParentCallback();
            })
        }
    };

    if (props.attempt)
        return (
            <div>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <div>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >Back</Button>
                                        {activeStep === steps.length - 1 ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >Finish</Button>
                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >Next/Skip</Button>
                                        }
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Contact />
            </div>
        )
    else
        handleSubmit()
}

export default Quiz3Ques