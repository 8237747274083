import React, { useContext } from "react";
import { UserContext } from "./Providers/UserProvider";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Main from './Main'
import LandingPage from './Components/FE Design/LandingPage'
import Video from './Components/Video'
import SMCVideo from './Components/SMCVideo'
import SMCourse from './Components/SMCourse'
import TaskMenu from './Components/TaskMenu'
import Dashboard from './Admin Stuff/Dashboard'
import TestQuiz from './Components/Quizzes/TestQuiz'
import AboutUs from './Components/AppInfo/AboutUs'
import ContactUs from './Components/AppInfo/ContactUs'
import BlogContent from './Components/Blog/BlogContent'
import Agencies from './Components/FE Design/Agencies'


const SubApp = () => {

    const user = useContext(UserContext);

    if (user)
        return (
            <Router>
                <Switch>
                    <Route exact path='/' component={() => <Main uid={user.uid} />} />
                    <Route exact path='/video/:id' render={(props) => <Video info={user} {...props} />} />
                    <Route exact path='/test' component={() => <TestQuiz uid={user.uid} name={user.displayName} email={user.email} />} />
                    <Route exact path='/admin' component={() => <Dashboard uid={user.uid} name={user.displayName} photoURL={user.photoURL} />} />
                    <Route exact path='/task/:id' render={(props) => <TaskMenu info={user} {...props} />} />
                    <Route exact path='/testing' component={() => <LandingPage />} />
                    <Route exact path='/aboutus' component={() => <AboutUs />} />
                    <Route exact path='/blogs/:title' component={BlogContent} />
                    <Route exact path='/contactus' component={() => <ContactUs />} />
                    <Route exact path='/agency' component={() => <Agencies />} />
                    <Route exact path='/smc' component={() => <SMCourse info={user} />} />
                    <Route exact path='/smc/video/:id' render={(props) => <SMCVideo info={user} {...props} />} />
                </Switch>
            </Router>
        );
    else
        return (
            <Router>
                <Switch>
                    <Route exact path='/' component={() => <Main uid={null} />} />
                    <Route exact path='/blogs/:title' component={BlogContent} />
                    <Route exact path='/testing' component={() => <LandingPage />} />
                    <Route exact path='/aboutus' component={() => <AboutUs />} />
                    <Route exact path='/contactus' component={() => <ContactUs />} />
                    <Route exact path='/agency' component={() => <Agencies />} />
                    <Route exact path='/smc' component={() => <Main uid={null} />} />
                </Switch>
            </Router>
        )
}

export default SubApp