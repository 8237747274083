import firebase from 'firebase';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCXTTzVSVAg6IvScy9GyHixOGMZnz-ctzI",
    authDomain: "monk-elearning.firebaseapp.com",
    projectId: "monk-elearning",
    storageBucket: "monk-elearning.appspot.com",
    messagingSenderId: "125173220310",
    appId: "1:125173220310:web:a33bbf3fac6736d93d9a77",
    measurementId: "G-GV2LSFZ0WT"
  };
// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
    auth.signInWithPopup(provider);
};

const prov = new firebase.auth.FacebookAuthProvider();
export const signInWithFb = () => {
    auth.signInWithPopup(prov);
};

export default fire;