import React, { Component } from 'react'
import fire from '../config/fire'
import Loader from '../Loader';
import Menu from './Menu'
import tasks from './TasksList'
import Header from './Header'
import BooleanTask1Quiz from './Quizzes/BooleanTask1Quiz'
import BooleanTask2Quiz from './Quizzes/BooleanTask2Quiz'
import BooleanTask3Quiz from './Quizzes/BooleanTask3Quiz'
import BooleanTask4Quiz from './Quizzes/BooleanTask4Quiz'
import BooleanTask5Quiz from './Quizzes/BooleanTask5Quiz'
import BooleanTask6Quiz from './Quizzes/BooleanTask6Quiz'
import BooleanTask7Quiz from './Quizzes/BooleanTask7Quiz'
import BooleanTask8Quiz from './Quizzes/BooleanTask8Quiz'
import BooleanTask9Quiz from './Quizzes/BooleanTask9Quiz'
import BooleanTask10Quiz from './Quizzes/BooleanTask10Quiz'
import BooleanTask11Quiz from './Quizzes/BooleanTask11Quiz'
import BooleanTask12Quiz from './Quizzes/BooleanTask12Quiz'
import ProbingTask1Quiz from './Quizzes/ProbingTask1Quiz'
import Quiz1Quiz from './Quizzes/Quiz1Quiz'
import Quiz2Quiz from './Quizzes/Quiz2Quiz'
import Quiz3Quiz from './Quizzes/Quiz3Quiz'
import Task1Quiz from './Quizzes/Task1Quiz'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class TaskMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: true,
            value: 0,
            users: [],
            confirm: '',
            pop: true
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let list = []
        fire.database().ref('Permissions/Weekly/uid').once('value', snapshot => {

            this.setState({ users: Object.keys(snapshot.val()) })
        })

        tasks.map(h => {
            if (h.id == this.props.match.params.id)
                fire
                    .database()
                    .ref(h.name)
                    .once('value', snapshot => {
                        if (snapshot.hasChild(this.props.info.uid))
                            this.setState({
                                pop: false,
                                confirm: 'YES'
                            })
                    })
        })
    }

    handleChange = (event, index) => {
        this.setState({ value: index });
    };

    rerenderParentCallback = (val) => {
        this.setState({ open: val });
    }

    handleCancel = () => {
        this.setState({
            confirm: 'NO',
            pop: false
        });
    };

    handleAccept = () => {
        this.setState({
            confirm: 'YES',
            pop: false
        });
    };

    handleYes = () => {
        this.setState({
            confirm: '',
            pop: true
        });
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                confirm: '',
                pop: true
            })

            this.componentDidMount()
        };
    };

    render() {
        return (
            <div>
                <Loader />
                {
                    this.state.users.includes(this.props.info.uid) ?
                        <div>
                            <Header />
                            <Menu open={this.state.open} rerenderParentCallback={this.rerenderParentCallback} />
                            <div className={this.state.open ? "contentShift" : "content"}>
                                <Dialog
                                    open={this.state.pop}
                                    className='task-men'
                                >
                                    <DialogTitle id="alert-dialog-title">Do you really want to proceed for the Task?</DialogTitle>
                                    <DialogContent>
                                        Maku sure you're in a quite space. Goodluck!
                                        <div style={{ height: '60px' }}></div>
                                    </DialogContent>
                                    <DialogActions style={{ padding: '25px' }} >
                                        <Button onClick={this.handleAccept} style={{ marginRight: '20px' }} color="primary" variant='contained'>YES, PROCEED</Button>
                                        <Button onClick={this.handleCancel} color="secondary" variant='contained'>CANCEL</Button>
                                    </DialogActions>
                                </Dialog>
                                {
                                    this.state.confirm === 'NO' ? <div style={{ display: 'flex', height: '700px', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ width: '300px', height: '200px' }}>
                                            <p style={{ float: 'right' }}>Do you want to Attempt now?</p>
                                            <br />
                                            <p></p>
                                            <br />
                                            <Button style={{ float: 'right' }} color='primary' onClick={this.handleYes} variant='contained'>YES</Button>
                                        </div>
                                    </div> :
                                        this.state.confirm === 'YES' ?
                                            tasks.map(h => {
                                                if (h.id == this.props.match.params.id && h.id == 1)
                                                    return (<Task1Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 2)
                                                    return (<Quiz1Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 3)
                                                    return (<Quiz2Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 4)
                                                    return (<Quiz3Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 5)
                                                    return (<BooleanTask1Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 6)
                                                    return (<BooleanTask2Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 7)
                                                    return (<BooleanTask3Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 8)
                                                    return (<BooleanTask4Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 9)
                                                    return (<BooleanTask5Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 10)
                                                    return (<BooleanTask6Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 11)
                                                    return (<BooleanTask7Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 12)
                                                    return (<BooleanTask8Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 13)
                                                    return (<BooleanTask9Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 14)
                                                    return (<BooleanTask10Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 15)
                                                    return (<BooleanTask11Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 16)
                                                    return (<BooleanTask12Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                                else if (h.id == this.props.match.params.id && h.id == 17)
                                                    return (<ProbingTask1Quiz uid={this.props.info.uid} name={this.props.info.displayName} email={this.props.info.email} />)
                                            }) : ''
                                }
                            </div>
                        </div > : <div align="center center" style={{ padding: '20px' }}><h2>No Permission! Please reach out to admin to get yourself enrolled.<br />Thank You!</h2></div>
                }
            </div>
        )
    }
}

export default TaskMenu