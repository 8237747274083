import React, { Component } from 'react';
import fire from '../config/fire';
import { Button, Grid } from '@material-ui/core';
import { InputLabel, NativeSelect } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Swal from 'sweetalert2';
import Drawing from './Drawing';
import moment from 'moment';

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quiz: 'task1',
      detail: [],
      row: 5,
    };
  }

  handleQuiz = (event) => {
    this.setState({
      quiz: event.target.value,
    });

    this.mount(event.target.value);
  };

  mount(quiz1) {
    // if (quiz === 'All') {
    /*let l = []

            tasks.map(data => {

                console.log(data.name)
                fire.database().ref(data.name).once('value', snapshot => {

                    console.log(snapshot.val())
                    snapshot.forEach(snap => {
                        l.push(snap.val());
                    })
                })
                let ans = l.sort(function (a, b) {

                    return moment(b.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") - moment(a.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x");
                });

                this.setState({ detail: ans })
            })*/
    // } else {
    fire
      .database()
      .ref(quiz1)
      .once('value', (snapshot) => {
        let list = [];

        snapshot.forEach((snap) => {
          list.push(snap.val());
        });

        let ans = list.sort(function (a, b) {
          return (
            moment(b.date, 'DD/MM/YYYY hh:mm:ss am/pm').format('x') -
            moment(a.date, 'DD/MM/YYYY hh:mm:ss am/pm').format('x')
          );
        });

        this.setState({ detail: ans });
      });
    // }
  }

  componentDidMount() {
    this.mount(this.state.quiz);
  }

  handleScore = (id, q) => {
    console.log(id, q);

    let val = document.getElementById(id).value;
    console.log(val);
    fire.database().ref(q).child(id).update({
      score: val,
    });

    Swal.fire({
      icon: 'success',
      title: 'Score Updated',
    });

    this.mount(this.state.quiz);
  };

  handleEdit = (id, q) => {
    console.log(id, q);

    fire.database().ref(q).child(id).update({
      score: '',
    });

    this.mount(this.state.quiz);
  };

  handleNext = () => {
    let dataLength = this.state.row;
    this.setState({ row: (dataLength += 5) });
  };

  rerenderParentCallback = () => {
    this.componentDidMount();
  };

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <p></p>
          </Grid>
        </Grid>
        <Grid container className='top'>
          <Grid item xs={6} style={{ padding: '20px' }}>
            <InputLabel htmlFor='quiz'>Quiz</InputLabel>
            <NativeSelect
              id='quiz'
              name='quiz'
              value={this.state.quiz}
              onChange={this.handleQuiz}>
              <option value='task1'>task1</option>
              <option value='quiz1'>quiz1</option>
              <option value='quiz2'>quiz2</option>
              <option value='quiz3'>quiz3</option>
              <option value='BooleanTask1'>BooleanTask1</option>
              <option value='BooleanTask2'>BooleanTask2</option>
              <option value='BooleanTask3'>BooleanTask3</option>
              <option value='BooleanTask4'>BooleanTask4</option>
              <option value='BooleanTask5'>BooleanTask5</option>
              <option value='BooleanTask6'>BooleanTask6</option>
              <option value='BooleanTask7'>BooleanTask7</option>
              <option value='BooleanTask8'>BooleanTask8</option>
              <option value='BooleanTask9'>BooleanTask9</option>
              <option value='BooleanTask10'>BooleanTask10</option>
              <option value='BooleanTask11'>BooleanTask11</option>
              <option value='BooleanTask12'>BooleanTask12</option>
              <option value='ProbingTask1'>ProbingTask1</option>
              <option value='batch'>batch</option>
            </NativeSelect>
          </Grid>
          <Grid item xs={6} sm={4} md={2} style={{ padding: '20px' }}>
            <p>
              <b>Total Entries: {this.state.detail.length}</b>
            </p>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{ overflowX: 'auto', marginTop: '20px', padding: '10px' }}>
            <table className='admin-table'>
              <thead className='thead-dark'>
                <tr>
                  <th>Attended on</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Quiz</th>
                  <th>Answers</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {this.state.detail.slice(0, this.state.row).map((i) => {
                  return (
                    <tr key={i.uid}>
                      <td>{i.date}</td>
                      <td>{i.name}</td>
                      <td>{i.email}</td>
                      <td>{i.quiz}</td>
                      <td>
                        <Drawing
                          rerenderParentCallback={this.rerenderParentCallback}
                          quiz={i.quiz}
                          uid={i.uid}
                          scores={i.scores}
                        />
                      </td>
                      {i.score === '' ? <td>TBD*</td> : <td>{i.score}</td>}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {this.state.row >= this.state.detail.length ? (
              <div className='more' align='center'>
                No More Entries
              </div>
            ) : (
              <div className='more' align='center'>
                <Button
                  onClick={this.handleNext}
                  variant='outlined'
                  color='primary'
                  size='large'>
                  View more
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Admin;
