const SMCvideos = [
    {
        "id": 583049586,
        "name": "Day-1",
        "about": "Sourcing Master Class-Day 1"
    },
    {
        "id": 583049953,
        "name": "Day-2",
        "about": "Sourcing Master Class-Day 2"
    },
    {
        "id": 583050204,
        "name": "Day-3",
        "about": "Sourcing Master Class-Day 3"
    },
    {
        "id": 583050474,
        "name": "Day-4",
        "about": "Sourcing Master Class-Day 4"
    },
    {
        "id": 583051083,
        "name": "Day-5",
        "about": "Sourcing Master Class-Day 5"
    },
    {
        "id": 591396845,
        "name": "Day-6",
        "about": "Sourcing Master Class-Day 6"
    },
    {
        "id": 591397051,
        "name": "Day-7",
        "about": "Sourcing Master Class-Day 7"
    },
    {
        "id": 591398170,
        "name": "Day-8",
        "about": "Sourcing Master Class-Day 8"
    },
    {
        "id": 591403846,
        "name": "Day-9",
        "about": "Sourcing Master Class-Day 9"
    },
    {
        "id": 591404079,
        "name": "Day-10",
        "about": "Sourcing Master Class-Day 10"
    },
    {
        "id": 591404267,
        "name": "Day-11",
        "about": "Sourcing Master Class-Day 11"
    },
    {
        "id": 591404935,
        "name": "Day-12",
        "about": "Sourcing Master Class-Day 12"
    }
];

export default SMCvideos
