import { Button, Grid } from '@material-ui/core';
import React from 'react'
import { Component } from 'react';
import Loader from './Loader'
import Card2 from './Card2'
import './fe.css'
import Header from '../Header'
import DeskFooter from './DeskFooter'
import CountUp from 'react-countup';
import Testimonial from '../Testimonials/Testimonial'
import 'bootstrap/dist/css/bootstrap.css';

class Agencies extends Component {

    render() {
        return (
            <div>
                <Header />
                <Grid container>

                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                hello
                            </div>
                            <div className="carousel-item">
                                bye
                            </div>
                            <div className="carousel-item">
                                take care
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <div align="center">
                        <Grid container>
                            <Grid item md={7}>
                                <h2>Sourcing</h2>
                                <br />
                                <p>Learn data driven sourcing & adopt some intriguing concepts like<br /> BigData & Probabilistic models in sourcing.</p>
                            </Grid>
                            <Grid item md={3}>
                                <img src="/images/engagement.png" />
                            </Grid>
                        </Grid>
                    </div>
                    <div align="center">
                        <Grid container>
                            <Grid item md={7}>
                                <h2>Engagement</h2>
                                <br />
                                <p>Learn the art of engaging contextually with hiring managers & candidates.<br />Want to learn the power of storytelling?</p>
                            </Grid>
                            <Grid item md={3}>
                                <img src="/images/engagement.png" />
                            </Grid>
                        </Grid>
                    </div>
                    <div align="center">
                        <Grid container>
                            <Grid item md={7}>
                                <h2>Interviewing</h2>
                                <br />
                                <p>A module for hiring managers to adopt the best practices of interviewing<br /> & learn the best probing techniques.</p>
                            </Grid>
                            <Grid item md={3}>
                                <img src="/images/engagement.png" />
                            </Grid>
                        </Grid>
                    </div>

                </Grid>
                <Grid container className='cards-cont'>
                    <Grid align="center" item xs={12} md={12}>
                        <h1>Looking To Hire Junior Recruiters?</h1>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card2 title='For Agencies' subtitle='Hire RecruitingMonk Certified Recruiters' im='https://assets.scaler.com/assets/scaler_branding/svg/scaler-academy-94ac1fb3186ca3dd2d7a8118102b1b565df1486ec9824bc84f3a98c32b131369.svg.gz' />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card2 title='For Corporate' subtitle='Hire Recruiters nurtured for more than 200 hours program under RecruitingMonk mentorship program' im='https://assets.scaler.com/assets/scaler_branding/svg/scaler-edge-a3444475f1d90d61d3dcbd99561a09e5da2c820e1f8d55de61f7fc5e7c46ae25.svg.gz' />
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: '#fff' }}>
                    <Grid item xs={12} md={7} style={{ paddingLeft: '20px' }}>
                        <h2 className="elw2">Want to assess your tech<br />recruiting skills?</h2>
                        <br />
                        <br />
                        <Button size='large' id='talk2' variant="contained" href="https://quiz.recruitingmonk.com/" target="_blank">Visit Now</Button>
                    </Grid>
                    <Grid item id='butt' xs={12} md={3}>
                        <img src="https://recruitingmonk.com//wp-content/uploads/2020/09/loader-gif.gif" />
                    </Grid>
                </Grid>
                <Grid container style={{ backgroundColor: '#b32800', textAlign: 'center' }}>
                    <Grid item md={12} align='center' className='land-first2'>
                        <p style={{ color: '#fff' }}><b style={{ color: '#f99c38' }}>Monk</b> impact so far</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} style={{ paddingBottom: '20px' }}>
                        <h2 className="elw">Recruiters</h2>
                        <CountUp style={{ color: '#f99c38', fontSize: '48px', fontWeight: '500' }} delay={2} start={0}
                            end={11000}
                            duration={5} /><span style={{ color: '#f99c38', fontSize: '42px', fontWeight: '500' }}>+</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} style={{ paddingBottom: '20px' }}>
                        <h2 className="elw">Companies</h2>
                        <CountUp style={{ color: '#f99c38', fontSize: '48px', fontWeight: '500' }} delay={2} start={0}
                            end={1000}
                            duration={5} /><span style={{ color: '#f99c38', fontSize: '42px', fontWeight: '500' }}>+</span>
                    </Grid>
                    <Grid item xs={6} md={3} style={{ paddingBottom: '20px' }}>
                        <h2 className="elw">Sessions</h2>
                        <CountUp style={{ color: '#f99c38', fontSize: '48px', fontWeight: '500' }} delay={2} start={0}
                            end={600}
                            duration={5} /><span style={{ color: '#f99c38', fontSize: '42px', fontWeight: '500' }}>+</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} style={{ paddingBottom: '20px' }}>
                        <h2 className="elw">Community</h2>
                        <CountUp style={{ color: '#f99c38', fontSize: '48px', fontWeight: '500' }} delay={2} start={0}
                            end={3000}
                            duration={5} /><span style={{ color: '#f99c38', fontSize: '42px', fontWeight: '500' }}>+</span>
                    </Grid>
                </Grid>
                <Grid container className='land'>
                    <Testimonial />
                </Grid>
                <Grid container className='land2'>
                    <Grid item xs={12} md={12} align='center' className='land-first'>
                        <p>Our Trainees work at reputed <b style={{ color: '#b32800' }}>companies</b></p>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/1.png" />
                        </Grid>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/2.png" />
                        </Grid>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/3.png" />
                        </Grid>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/4.png" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/5.png" />
                        </Grid>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/6.png" />
                        </Grid>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/7.png" />
                        </Grid>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/8.png" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/9.png" />
                        </Grid>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/10.png" />
                        </Grid>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/11.png" />
                        </Grid>
                        <Grid item md={2} sm={6} xs={6}>
                            <img src="/images/12.png" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} align='center' className='pp'>
                        <p>and 1000+ employer partners</p>
                    </Grid>
                </Grid>
                <DeskFooter />
            </div>
        )
    }
}

export default Agencies