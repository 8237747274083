import React, { Component } from 'react'
import { Grid, Divider } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom'
import Loader from '../Loader'

class SMCMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: this.props.open,
            dis: 'none'
        }
    }

    handleDrawerOpen = () => {
        this.setState({ open: true, dis: 'none' });
        this.props.rerenderParentCallback(true)
    };

    handleDrawerClose = () => {
        this.setState({ open: false, dis: 'block' });
        this.props.rerenderParentCallback(false)
    };

    render() {
        return (

            <div>
                <Loader />
                <Grid container id="menu-draw">
                    <Grid item xs={12}>
                        <IconButton
                            onClick={this.handleDrawerOpen}
                            className="icons"
                            id="menu-m"
                            style={{ display: this.state.dis, width: '50px', height: '50px' }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <SwipeableDrawer
                            variant="persistent"
                            open={this.state.open}
                            onClose={this.handleDrawerClose}
                            onOpen={this.handleDrawerOpen}
                        >
                            <div className="drawerHeader">
                                <Typography variant="h6" style={{ paddingLeft: '5px' }}><b>Course Content</b></Typography>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                            <Divider />
                            <br />
                            <div className="contribute-section">
                                <List>
                                    <ListItem><Link className="links" to="/smc/video/583049586">- Day 1</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/smc/video/583049953">- Day 2</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/smc/video/583050204">- Day 3</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/smc/video/583050474">- Day 4</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/smc/video/583051083">- Day 5</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/smc/video/591396845">- Day 6</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/smc/video/591397051">- Day 7</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/smc/video/591398170">- Day 8</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/smc/video/591403846">- Day 9</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/smc/video/591404079">- Day 10</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/smc/video/591404267">- Day 11</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/smc/video/591404935">- Day 12</Link></ListItem>
                                </List>
                                <div style={{ height: '100px', backgroundColor: 'white' }}></div>
                            </div>
                        </SwipeableDrawer>
                    </Grid >
                </Grid>
            </div>
        )
    }
}

export default SMCMenu
