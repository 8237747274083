import React, { Component } from 'react';
import {
    Button,
    Dialog, DialogContent, Grid, TextField
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import './fe.css'
import fire from '../../config/fire';
import Swal from "sweetalert2";

class ContactAgency extends Component {

    constructor(props) {
        super(props)

        this.state = {
            name: '',
            email: '',
            number: '',
            company: ''
        };
    }

    handleText = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleSubmit = (event) => {
        fire.database().ref("AgencyContact").push(this.state)

        this.handleClose()

        Swal.fire({
            icon: "success",
            title: "Awesome! We'll get back to you shortly!"
        })
    };

    handleOpen = () => {
        this.setState({
            open: true
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    componentDidMount() {
        console.log(this.props)
    }

    render() {
        return (
            <div align="center" >
                <Button variant='contained' color="secondary" onClick={this.handleOpen}>Learn More</Button>
                <Dialog open={this.state.open} onClose={this.handleClose} fullWidth>
                    <Button style={{ alignSelf: 'flex-end', width: '50px', height: '50px' }} onClick={this.handleClose}><CancelIcon /></Button>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField variant="outlined" name="name" placeholder="Name" label="Name" value={this.state.name} onChange={this.handleText} fullWidth />
                                <br />
                                <br />
                                <TextField variant="outlined" name="company" placeholder="Company Name" label="Company" value={this.state.company} onChange={this.handleText} fullWidth />
                                <br />
                                <br />
                                <TextField variant="outlined" name="email" placeholder="Email" label="Email" value={this.state.email} onChange={this.handleText} fullWidth />
                                <br />
                                <br />
                                <TextField variant="outlined" name="number" placeholder="Mobile (Preferrably Whatsapp)" label="Mobile" value={this.state.number} onChange={this.handleText} fullWidth />
                                <br />
                                <br />
                                <Button variant="contained" color="primary" onClick={this.handleSubmit} fullWidth >Submit</Button>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <br />
                                <hr />
                                <h3 align="center">Or</h3>
                            </Grid>
                            <Grid align="center" item xs={12}>
                                <h1>Contact Us on Whatsapp.<br />Follow Link Below<br /></h1>
                                <br />
                                <Button size="large" variant="contained" href="https://wa.me/message/XJKMT6EAGWRHI1" target="_blank" className="whatsapp">Chat With Us</Button>
                                <br />
                                <br />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default ContactAgency;