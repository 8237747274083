import React, { Component } from 'react';
import Countdown from 'react-countdown';
import Quiz1Ques from './quiz1'
import Grid from '@material-ui/core/Grid';
import fire from '../../config/fire'

class Quiz1Quiz extends Component {

    constructor(props) {
        super(props)

        this.state = {
            fill: true,
            attempt: 1,
            detail: [],
            ques: [],
            score: '',
            maxscore: '',
            comment: ''
        }
    }

    handleText = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    };

    handleAttempt = () => {
        this.setState({
            attempt: 0
        });
    };

    componentDidMount() {
        fire
            .database()
            .ref("quiz1")
            .once('value', snapshot => {
                if (snapshot.hasChild(this.props.uid))
                    this.setState({
                        fill: false
                    })
            });

        if (this.state.fill === true) {
            fire.database().ref("quiz1/" + this.props.uid).once('value', snapshot => {
                let list = []
                if (snapshot.val() !== null) {
                    this.setState({
                        score: snapshot.val().score,
                        maxscore: snapshot.val().maxscore,
                        comment: snapshot.val().comment
                    })

                    snapshot.forEach(snap => {
                        list.push(snap.val());
                    })

                    this.setState({ detail: list })
                }
            })

            fire.database().ref("Questions/quiz1").once('value', snapshot => {
                let l = []
                let qn = []

                snapshot.forEach(snap => {
                    l.push(snap.val());
                })

                for (var i = l.length / 2; i < l.length; i++)
                    qn.push(l[i])

                this.setState({ ques: qn })
            })
        }
    }

    rerenderParentCallback = () => {
        this.componentDidMount()
    }

    renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            this.handleAttempt()
            return <h2>Time's Up!!</h2>
        } else {
            // Render a countdown
            return (
                <Grid item xs={12} className="clock">
                    <h1>{minutes}:{seconds}</h1>
                </Grid>
            )
        }
    };

    render() {

        return (
            <div>
                {
                    this.state.fill ?
                        <Grid container className="quiz">
                            <Grid item xs={12}>
                                <Countdown
                                    date={Date.now() + 600000}
                                    renderer={this.renderer}
                                /></Grid>
                            <Grid item xs={12} md={7} className="questions">
                                <Quiz1Ques rerenderParentCallback={this.rerenderParentCallback} uid={this.props.uid} fill={this.state.fill} attempt={this.state.attempt} name={this.props.name} email={this.props.email} />
                            </Grid>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={12} style={{ padding: '10px' }}>
                                <div align='center' className='qna'>
                                    <div className='top-quiz-title'>
                                        <h3>Quiz-1</h3>
                                        {this.state.score !== '' ? <h4>Your Score: {this.state.score}/{this.state.maxscore}</h4> : <h4>Score Awaited</h4>}
                                    </div>
                                    <div className="show-all">
                                        {
                                            this.state.ques.map((data, index) => {
                                                return (
                                                    <div>
                                                        <p className="show-ques">{data}</p>
                                                        {
                                                            Array.isArray(this.state.detail[index]) ?
                                                                <div>
                                                                    {
                                                                        this.state.detail[index].map(data => {
                                                                            if (data.isChecked === true)
                                                                                return (
                                                                                    <p class="quiz-ans"><b>Ans.</b> {(data.value).split('\n').map(str => <p>{str}</p>)}</p>
                                                                                )
                                                                        })
                                                                    }
                                                                </div>
                                                                :
                                                                <div class="quiz-ans"><b>Ans.</b> {(this.state.detail[index]).split('\n').map(str => <p>{str}</p>)}</div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div>
                                        {
                                            this.state.comment ? <p>Comment: {this.state.comment}</p> : <p></p>
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                }
            </div>
        );
    }
}

export default Quiz1Quiz;