import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Button, Grid } from '@material-ui/core';
import fire from '../config/fire';
import { TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import parse from 'html-react-parser';

class Drawing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      top: false,
      detail: [],
      ques: [],
      category: [],
      scores: this.props.scores,
      com: '',
      dis: false,
    };
  }

  componentDidMount() {
    fire
      .database()
      .ref(`Questions/${this.props.quiz}`)
      .once('value', (snapshot) => {
        let l = [];
        let qn = [];
        let cat = [];

        snapshot.forEach((snap) => {
          l.push(snap.val());
        });

        for (var i = l.length / 2; i < l.length; i++) qn.push(l[i]);

        for (var i = 0; i < l.length / 2; i++) cat.push(l[i]);

        this.setState({ ques: qn, category: cat });
      });
    console.log(this.props.quiz);
    fire
      .database()
      .ref(`${this.props.quiz}/${this.props.uid}`)
      .once('value', (snapshot) => {
        let list = [];

        snapshot.forEach((snap) => {
          list.push(snap.val());
        });
        console.log(list);

        //if (((snapshot.val()).comment).length > 0)
        this.setState({ detail: list, com: snapshot.val().comment, dis: true });
        //else
        // this.setState({ detail: list, com: (snapshot.val()).comment, dis: false })
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.quiz != prevProps.quiz) {
      this.componentDidMount();
    }

    if (this.props.scores.length != prevProps.scores.length) {
      this.componentDidMount();
    }
  }

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    this.setState({ [anchor]: open });
  };

  handleScore = () => {
    let s = [];

    for (var i = 0; i < this.props.scores.length; i++) {
      var id = 'score' + i;
      console.log(id);
      s.push(document.getElementById(id).value);
    }

    let sum = 0;
    for (var i = 0; i < s.length; i++) sum = sum + parseFloat(s[i]);

    sum = sum.toString();
    fire.database().ref(this.props.quiz).child(this.props.uid).update({
      score: sum,
      scores: s,
      comment: this.state.com,
    });

    this.setState({ scores: s });
    this.componentDidMount();
    this.props.rerenderParentCallback();
  };

  handleChange = (event) => {
    this.setState({ com: event.target.value });
  };

  handleEdit = () => {
    let n = [];

    for (var i = 0; i < this.props.scores.length; i++) n.push('');

    console.log(n);
    fire.database().ref(this.props.quiz).child(this.props.uid).update({
      score: '',
      scores: n,
      comment: '',
    });

    this.setState({ scores: n });
    this.componentDidMount();
    this.props.rerenderParentCallback();
  };

  list = (anchor) => (
    <div className='drawer' role='presentation'>
      <Grid container>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '20px',
            }}>
            <b style={{ fontSize: '28px' }}>{this.props.quiz}</b>
            <Button
              variant='outlined'
              size='small'
              color='primary'
              onClick={this.toggleDrawer('top', false)}>
              Close
            </Button>
          </div>
          <div>
            <ol>
              {this.state.ques.map((data, index) => {
                return (
                  <div>
                    {Array.isArray(data) ? (
                      <li>
                        {data.map((d) => {
                          return <p>{parse(d)}</p>;
                        })}{' '}
                        (<b> Category: {this.state.category[index]}</b>)
                      </li>
                    ) : (
                      <div>
                        <li>
                          {parse(data)} (
                          <b> Category: {this.state.category[index]}</b>)
                        </li>
                      </div>
                    )}
                    <br />
                    {Array.isArray(this.state.detail[index]) ? (
                      <div>
                        {this.state.detail[index].map((data) => {
                          if (data.isChecked === true)
                            return (
                              <p>
                                <b>Ans.</b> {data.value}
                              </p>
                            );
                        })}
                        <div style={{ padding: '5px', marginBottom: '10px' }}>
                          {this.state.scores[index] === '' ? (
                            <Grid container>
                              <Grid item>
                                <TextField
                                  type='number'
                                  id={'score' + index}
                                  placeholder='Score'
                                  variant='outlined'
                                  size='small'
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <span>Score: {this.state.scores[index]}</span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <span>
                          <b>Ans.</b>{' '}
                        </span>
                        {this.state.detail[index]}
                        <div style={{ padding: '5px', marginBottom: '10px' }}>
                          {this.state.scores[index] === '' ? (
                            <Grid container>
                              <Grid item>
                                <TextField
                                  type='number'
                                  id={'score' + index}
                                  placeholder='Score'
                                  variant='outlined'
                                  size='small'
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <span>Score: {this.state.scores[index]}</span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              <br />
              <TextField
                disabled={this.state.dis}
                variant='outlined'
                placeholder='Add any comment'
                value={this.state.com}
                onChange={this.handleChange}
                multiline
                rows={2}
                rowsMax={5}
                fullWidth
              />
              <div style={{ height: '50px' }}></div>
              {this.state.scores[0] === '' ? (
                <Button
                  onClick={this.handleScore.bind(this)}
                  color='primary'
                  variant='outlined'>
                  Submit Score & Comment
                </Button>
              ) : (
                <div>
                  <p>
                    Total Score:{' '}
                    {this.state.detail[this.state.detail.length - 3]}{' '}
                    <IconButton
                      aria-label='delete'
                      size='small'
                      color='primary'
                      onClick={this.handleEdit.bind(this)}>
                      <EditIcon />
                    </IconButton>
                  </p>
                </div>
              )}
            </ol>
          </div>
        </Grid>
      </Grid>
    </div>
  );

  render() {
    return (
      <div>
        <Button
          variant='outlined'
          color='primary'
          onClick={this.toggleDrawer('top', true)}>
          Check
        </Button>
        <Drawer
          anchor='top'
          open={this.state.top}
          onClose={this.toggleDrawer('top', false)}>
          {this.list('top')}
        </Drawer>
      </div>
    );
  }
}

export default Drawing;
