import React from 'react';

function Loader() {
  return (
    <div className="Login-Load">
      <div className="Login-Loader-bg" >
        <div align="center" className="Login-loader-img">
          <img src="/images/loader.png" />
          <div class="Login-loader-shadow"></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Loader