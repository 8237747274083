import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import fire from '../config/fire'
import Loader from '../Loader';
import SMCMenu from './SMCMenu'
import Header from './Header'

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`admin-tabpanel-${index}`}
            aria-labelledby={`admin-tab-${index}`}
            className="admin-tabpanel"
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

class SMCourse extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: true,
            value: 0,
            users: []
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let list = []
        fire.database().ref('Permissions/SMC/uid').once('value', snapshot => {

            this.setState({ users: Object.keys(snapshot.val()) })
        })
    }

    handleChange = (event, index) => {
        this.setState({ value: index });
    };

    rerenderParentCallback = (val) => {
        this.setState({ open: val });
    }

    render() {
        return (
            <div>
                <Loader />
                {
                    this.state.users.includes(this.props.info.uid) ?
                        <div>
                            <Header />
                            <SMCMenu open={this.state.open} rerenderParentCallback={this.rerenderParentCallback} />
                            <div className={this.state.open ? "contentShift" : "content"}>
                                <h2 className="head-men">Day-1</h2>
                                <iframe className="vids" title="video" src="https://player.vimeo.com/video/583049586" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

                                <Grid container>
                                    <Grid item xs={12} style={{ padding: '20px' }}>
                                        <Tabs value={this.state.value} onChange={this.handleChange} aria-label="admin-tabs" className="admin-tabs" >
                                            <Tab label="Overview" />
                                        </Tabs>

                                        <TabPanel value={this.state.value} index={0}>
                                            <h3>About this lecture</h3>
                                            <p>Sourcing Master Class-Day 1.</p>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                            </div>
                        </div> : <div align="center center" style={{ padding: '20px' }}><h2>No Permission! Please reach out to admin to get yourself enrolled.<br />Thank You!</h2></div>
                }
            </div>
        )
    }
}

export default SMCourse
