import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import fire from '../config/fire'
import { Grid, Hidden } from '@material-ui/core';
import Swal from 'sweetalert2'
import DisplayQues from './DisplayQues';


class Question extends Component {

    constructor(props) {
        super(props);

        this.state = {
            question: "",
            dis: true,
            qid: new Date().getTime(),
            uid: this.props.user.uid,
            photo: this.props.user.photoURL,
            name: this.props.user.displayName
        };
    }

    handleQuestion = e => {

        if (e.target.value === "") {
            this.setState({
                dis: true,
                question: ""
            });
        }
        else {
            this.setState({
                question: e.target.value,
                dis: false
            });
        }
    };

    submitQuestion = e => {

        fire
            .database()
            .ref("Questions/Weekly")
            .child(this.state.qid)
            .set(this.state);
        
        Swal.fire(
            'Great!',
            'Your Question is Posted!',
            'success'
        )

        this.setState({
            question: "",
            dis: true,
            qid: new Date().getTime(),
            uid: this.props.user.uid,
            photo: this.props.user.photoURL,
            name: this.props.user.displayName
        });

    };

    render() {

        return (
            <Grid container>
                <Grid item xs={12} sm={9} md={9}>
                    <div className="Question-container" style={{ display: this.state.display }}>
                        <TextField fullWidth multiline value={this.state.question}
                            onChange={this.handleQuestion}
                            rowsMax={5} className="Question-field" rows={2}
                            label="Ask Question" placeholder="Type Your Question Here"
                            variant="outlined" />
                    </div>
                </Grid>
                <Hidden only={['lg', 'md', 'sm', 'xl']}><Grid item xs={9}></Grid></Hidden>
                <Grid item xs={3} sm={3} md={3}>
                    <div style={{ paddingTop: '20px' }} className="Question-container-buttons">
                        <Button size="small" variant="contained" className="Submit-Question"
                            disabled={this.state.dis}
                            onClick={this.submitQuestion}
                        >Ask</Button>
                    </div>
                </Grid>
                <DisplayQues ques={this.state.qid} user={this.props.user} />
            </Grid>
        );
    }
}

export default Question;