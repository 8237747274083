import React from 'react'
import { Grid } from '@material-ui/core';
import T1 from './T1'
import T2 from './T2'
import T3 from './T3'

function Testimonial() {
    return (
        <Grid container id="testimonials" style={{ backgroundColor: '#f3f7f7', justifyContent: 'space-evenly', marginTop: '30px', paddingBottom: '30px' }}>
            <Grid item xs={12} className="Test-Head">
                <h1>Recommendations</h1>
            </Grid>
            <Grid item xs={12} md={10} style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                <Grid item xs={12} md={3}>
                    <T1 />
                </Grid>
                <Grid item xs={12} md={3}>
                    <T2 />
                </Grid>
                <Grid item xs={12} md={3}>
                    <T3 />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Testimonial