import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Button, Grid } from '@material-ui/core';
import fire from '../config/fire'
import { TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import parse from 'html-react-parser'

let check = []
let info = 0

const quiz = ["task1", "quiz1", "quiz2", "quiz3", "BooleanTask1", "BooleanTask2", "BooleanTask3", "BooleanTask4", "BooleanTask5", "BooleanTask6", "BooleanTask7",
"BooleanTask8", "BooleanTask9", "BooleanTask10", "BooleanTask11", "BooleanTask12", "ProbingTask1"]

class NewDrawing extends Component {

    constructor(props) {
        super(props)

        this.state = {
            top: false,
            detail: [],
            ques: [],
            category: [],
            dis: false,
            any: true,
            scores: []
        }

        this.getAnswers = this.getAnswers.bind(this);
    }

    getAnswers(uid) {
        fire.database().ref(quiz[info] + "/" + uid).once('value', snapshot => {
            let list = []

            snapshot.forEach(snap => {
                list.push(snap.val());
            })
                
            this.setState({ detail: list, dis: false, scores: snapshot.val().scores })
        
        })
    }

    componentDidMount() {
        fire.database().ref(quiz[info]).once('value', snapshot => {
            check = Object.keys(snapshot.val())
               
        if(check.includes(this.props.uid)) {
            this.getAnswers(this.props.uid)
        }
        else {
            this.setState({ any: false})
        }
    })

        fire.database().ref("Questions/" + quiz[info]).once('value', snapshot => {
            let l = []
            let qn = []
            let cat = []

            snapshot.forEach(snap => {
                l.push(snap.val());
            })

            for (var i = l.length / 2; i < l.length; i++)
                qn.push(l[i])

            for (var i = 0; i < l.length / 2; i++)
                cat.push(l[i])

            this.setState({ ques: qn, category: cat })
        })
    }

    toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ [anchor]: open });
    };

    handleScore = () => {
        let s = []

        for (var i = 0; i < this.state.scores.length; i++) {
            var id = "score" + i
            console.log(id)
            s.push(document.getElementById(id).value)
        }

        let sum = 0
        for (var i = 0; i < s.length; i++)
            sum = sum + parseFloat(s[i])

        sum = sum.toString()
        fire.database().ref(quiz[info])
            .child(this.props.uid).update({
                'score': sum,
                'scores': s
            })

        this.setState({ scores: s })
        this.componentDidMount()
        this.props.rerenderParentCallback();
    }

    handleNext = (event) => {
        if(info === 16)
            info = 0
        else
            info =  info+1
        
        this.mount()

        this.mount()
    };

    mount() {
        this.componentDidMount()

        this.componentDidMount()
    }

    handleEdit = () => {
        let n = []

        for (var i = 0; i < this.state.scores.length; i++)
            n.push("")

        console.log(n)
        fire.database().ref(quiz[info])
            .child(this.props.uid).update({
                'score': "",
                'scores': n
            })

        this.setState({ scores: n })
        this.props.rerenderParentCallback();
    }

    list = (anchor) => (
        <div
            className="drawer"
            role="presentation"
        >
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                        <b style={{ fontSize: '28px' }}>{quiz[info]}</b>
                        <Button variant="outlined" size="small" color="primary" onClick={this.toggleDrawer('top', false)}>Close</Button>
                    </div>
                    <div>
                        <ol>
                            {
                                this.state.ques.map((data, index) => {
                                    return (
                                        <div>
                                            {
                                                Array.isArray(data) ?
                                                    <li>
                                                        {
                                                            data.map(d => {
                                                                return (
                                                                    <p>{parse(d)}</p>
                                                                )
                                                            })
                                                        } (<b> Category: {this.state.category[index]}</b>)
                                                    </li>
                                                    :
                                                    <div>
                                                        <li>{parse(data)} (<b> Category: {this.state.category[index]}</b>)</li>
                                                    </div>
                                            }
                                            <br />
                                            {
                                                Array.isArray(this.state.detail[index]) && this.state.any === true ?
                                                    <div>
                                                        {
                                                            this.state.detail[index].map(data => {
                                                                if (data.isChecked === true)
                                                                    return (
                                                                        <p><b>Ans.</b> {data.value}</p>
                                                                    )
                                                            })
                                                        }
                                                        <div style={{ padding: '5px', marginBottom: '10px' }}>
                                                            {
                                                                this.state.scores[index] === '' ?
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            <TextField type="number" id={"score" + index}
                                                                                placeholder="Score" variant="outlined" size="small" />
                                                                        </Grid>
                                                                    </Grid> : <span>Score: {this.state.scores[index]}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    this.state.any === true && (this.state.detail[index] !== undefined || this.state.detail[index] !== null) ?
                                                    <div>
                                                        <span><b>Ans.</b> {this.state.detail[index]}</span>
                                                        <div style={{ padding: '5px', marginBottom: '10px' }}>
                                                            {
                                                                this.state.scores[index] === "" ?
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            <TextField type="number" id={"score" + index}
                                                                                placeholder="Score" variant="outlined" size="small" />
                                                                        </Grid>
                                                                    </Grid> : <span>Score: {this.state.scores[index]}</span>
                                                            }
                                                        </div>
                                                    </div> : <div>Not Answered</div>
                                            }
                                        </div>
                                    )
                                })
                            }
                            <br />
                            <div style={{ height: '10px' }}></div>
                            <div style={{ display:'flex', justifyContent: 'space-between', padding: '20px' }}>
                            {
                                this.state.scores[0] === "" ?
                                    <div>
                                    <Button onClick={this.handleScore.bind(this)} color="primary" variant="outlined">Submit Score For This Task</Button>
                                    </div>
                                    :
                                    <div>
                                        <p>Total Score: {this.state.detail[this.state.detail.length - 3]} <IconButton aria-label="delete" size="small" color="primary" onClick={this.handleEdit.bind(this)}>
                                            <EditIcon />
                                        </IconButton></p>
                                    </div>
                            }
                            <Button onClick={this.handleNext.bind(this)} color="primary" variant="outlined">Next Task</Button>
                            </div>
                        </ol>
                    </div>
                </Grid>
            </Grid>
        </div>
    );

    render() {
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={this.toggleDrawer('top', true)}>Check</Button>
                <Drawer anchor="top" open={this.state.top} onClose={this.toggleDrawer('top', false)}>
                    {this.list('top')}
                </Drawer>
            </div>
        );
    }
}

export default NewDrawing