import React from "react";
import { Component } from 'react';
import Home from './Components/Home'
import Loader from './Loader'
import fire from './config/fire'

class Main extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let list = []
    fire.database().ref('Permissions/Weekly/uid').once('value', snapshot => {
      snapshot.forEach(snap => {
          list.push(snap.val());
      })

      list.map(data => {
        if( Math.ceil(Math.abs((new Date().getTime()) - new Date(data.date).getTime()) / (1000 * 60 * 60 * 24)) > 31 )
          fire.database().ref('Permissions/Weekly/uid').child(data.uid).remove()
      })
    })

    let list2 = []
    fire.database().ref('Permissions/SMC/uid').once('value', snapshot => {
      snapshot.forEach(snap => {
          list2.push(snap.val());
      })

      list2.map(data => {
        if( Math.ceil(Math.abs((new Date().getTime()) - new Date(data.date).getTime()) / (1000 * 60 * 60 * 24)) > 31 )
          fire.database().ref('Permissions/SMC/uid').child(data.uid).remove()
      })
    })
  }

  render() {
  return (
    <div>
      <Loader />
      <Home uid={this.props.uid} />
    </div>
  );
}
}
export default Main