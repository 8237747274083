import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import fire from '../config/fire'
import Header from './Header'
import videos from './VideosList'
import Loader from '../Loader'
import Question from './Question'
import parse from 'html-react-parser';
import Menu from './Menu'

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`admin-tabpanel-${index}`}
            aria-labelledby={`admin-tab-${index}`}
            className="admin-tabpanel"
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

class Video extends Component {
    constructor(props) {
        super(props)

        this.state = {
            users: [],
            open: true,
            value: 0
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let list = []
        fire.database().ref('Permissions/Weekly/uid').once('value', snapshot => {

            this.setState({ users: Object.keys(snapshot.val()) })
        })
    }

    handleChange = (event, index) => {
        this.setState({ value: index });
    };

    rerenderParentCallback = (val) => {
        this.setState({ open: val });
    }

    render() {
        return (
            <div>
                <Loader />
                {
                    this.state.users.includes(this.props.info.uid) ?
                        <div>
                            <Loader />
                            <Header />
                            <Menu open={this.state.open} rerenderParentCallback={this.rerenderParentCallback} />
                            <div className={this.state.open ? "contentShift" : "content"}>
                                <h2 className="head-men">{videos.map(h => {
                                    if (h.id == this.props.match.params.id)
                                        return (h.name)
                                })}</h2>
                                <iframe className="vids" title="video" src={"https://player.vimeo.com/video/" + this.props.match.params.id} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

                                <Grid container>
                                    <Grid item xs={12} style={{ padding: '20px' }}>
                                        <Tabs value={this.state.value} onChange={this.handleChange} aria-label="admin-tabs" className="admin-tabs" >
                                            <Tab label="Overview" />
                                            <Tab label="Discussions" />
                                            <Tab label="Notes" />
                                        </Tabs>

                                        <TabPanel value={this.state.value} index={0}>
                                            <h3>About this lecture</h3>
                                            <p>{videos.map(h => {
                                                if (h.id == this.props.match.params.id)
                                                    return (parse(h.about))
                                            })}</p>
                                        </TabPanel>
                                        <TabPanel value={this.state.value} index={1}>
                                            <Question user={this.props.info} />
                                        </TabPanel>
                                        <TabPanel value={this.state.value} index={2}>
                                            <p>Notes attachment will come here(if any)</p>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                            </div>
                        </div > : <div align="center center" style={{ padding: '20px' }}><h2>No Permission! Please reach out to admin to get yourself enrolled.<br />Thank You!</h2></div>
                }
            </div>
        )
    }
}

export default Video