import React, { Component } from 'react'
import { Grid, Divider } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link } from 'react-router-dom'
import Loader from '../Loader'

class Menu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: this.props.open,
            dis: 'none'
        }
    }

    handleDrawerOpen = () => {
        this.setState({ open: true, dis: 'none' });
        this.props.rerenderParentCallback(true)
    };

    handleDrawerClose = () => {
        this.setState({ open: false, dis: 'block' });
        this.props.rerenderParentCallback(false)
    };

    render() {
        return (

            <div>
                <Loader />
                <Grid container id="menu-draw">
                    <Grid item xs={12}>
                        <IconButton
                            onClick={this.handleDrawerOpen}
                            className="icons"
                            id="menu-m"
                            style={{ display: this.state.dis, width: '50px', height: '50px' }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <SwipeableDrawer
                            variant="persistent"
                            open={this.state.open}
                            onClose={this.handleDrawerClose}
                            onOpen={this.handleDrawerOpen}
                        >
                            <div className="drawerHeader">
                                <Typography variant="h6" style={{ paddingLeft: '5px' }}><b>Course Content</b></Typography>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                            <Divider />
                            <br />
                            <div className="contribute-section">
                                <Typography variant="h6">Section - I</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/video/539324609">- What is Recruitment?</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/539326441">- Recruitment Life Cycle</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/539324800">- Industry Overview</Link></ListItem>
                                </List>
                                <Typography variant="h6">Section - II</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/video/539326154">- Product vs Service</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/539327379">- Types of Product Firms</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/539326689">- Startups</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/1">- Task-1</Link></ListItem>
                                </List>
                                <Typography variant="h6">Section - III</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/video/539327164">- Types of Hiring</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/539324940">- Recruitment Life Cycle - Intro to JD</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/539325825">- JD Terminologies</Link></ListItem>
                                </List>
                                <Typography variant="h6">Section - IV</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/video/539325539">- JD Deconstruction - I</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/539326985">- Tips for JD Deconstruction</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/539325095">- Demystifying tech for Recruiters</Link></ListItem>
                                </List>
                                <Typography variant="h6">Section - V</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/video/539325245">- Introduction to SDLC</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/539327545">- Understanding Developer role for Recruiters</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/541149629">- JD Deconstruction - II</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/2">- Quiz-1</Link></ListItem>
                                </List>
                                <Typography variant="h6">Section - VI</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/video/541148721">- What is Boolean</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/3">- Quiz-2</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/5">- Boolean Task-1</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/541150972">- Task-1 Explanation</Link></ListItem>
                                </List>
                                <Typography variant="h6">Section - VII</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/video/541152257">- Technology-2 Infrastructure/Cloud</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/541150396">- Microservice Architecture</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/541150759">- Other General Technologies</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/552111000">- Learning Tech Clusters</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/4">- Quiz-3</Link></ListItem>
                                </List>
                                <Typography variant="h6">Section - VIII</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/task/6">- Boolean Task-2</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/541151405">- Task-2 Explanation</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/7">- Boolean Task-3</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/541151606">- Task-3 Explanation</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/8">- Boolean Task-4</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/552118315">- Task-4 Explanation</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/9">- Boolean Task-5</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/552125771">- Task-5 Explanation</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/10">- Boolean Task-6</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/552098249">- Task-6 Explanation</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/11">- Boolean Task-7</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/552132723">- Task-7 Explanation</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/12">- Boolean Task-8</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/552134052">- Task-8 Explanation</Link></ListItem>
                                </List>
                                <Typography variant="h6">Section - IX</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/video/552136307">- UI Designer & UI Developer Role</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/13">- Boolean Task-9</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/552137865">- Task-9 UI Developer</Link></ListItem>
                                </List>
                                <Typography variant="h6">Section - X</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/task/14">- Boolean Task-10</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/552139255">- Task-10 .Net Developer</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/15">- Boolean Task-11</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/552145480">- Task-11 Java Developer</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/16">- Boolean Task-12</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/552147194">- Task-12 Java Developer</Link></ListItem>
                                </List>
                                <Typography variant="h6">Section - XI</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/video/552213019">- Introduction to Naukri</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/552214727">- Screening or Probing</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/task/17">- Probing Task-1</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/554929328">- Boolean Mistakes</Link></ListItem>
                                </List>
                                <Typography variant="h6">Section - XII</Typography>
                                <List>
                                    <ListItem><Link className="links" to="/video/554929010">- Testing Role</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/554937838">- Task-14 Explanation</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/554934065">- Tweaking Boolean - In Ref to Data</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/555474683">- DevOps Engineer & Boolean Tweaks</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/555474550">- Candidate Pitch</Link></ListItem>
                                    <br />
                                    <ListItem><Link className="links" to="/video/555474782">- Followup Strategy</Link></ListItem>
                                </List>
                                <div style={{ height: '100px', backgroundColor: 'white' }}>
                                </div>
                            </div>
                        </SwipeableDrawer>
                    </Grid >
                </Grid>
            </div>
        )
    }
}

export default Menu