import React, { Component } from 'react';
import { Button, Grid } from '@material-ui/core';
import { signInWithGoogle, signInWithFb } from './config/fire'
import FacebookIcon from '@material-ui/icons/Facebook';

class NormalLogin extends Component {

    render() {
        return (
            <div align="center" >
                <Grid container className="login">
                    <Grid item xs={12}>
                        <p className="head">Login & Learn <b>Tech Recruiting</b></p>
                    </Grid>
                    <Grid item xs={12}>
                        <p></p>
                    </Grid>
                    <Grid item xs={12}>
                        <Button class="googleBtn" onClick={signInWithGoogle}>
                            <img
                                src="https://img.pngio.com/google-logo-png-circle-google-icon-png-white-transparent-google-logo-white-png-654_665.png"
                                alt="logo"
                            />Login With Google</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <p></p>
                    </Grid>
                    <Grid item xs={12}>
                        <Button class="fbBtn" onClick={signInWithFb}>
                            <FacebookIcon /> Login With Facebook</Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default NormalLogin;