import React from 'react'
import fire from '../../config/fire'
import Swal from "sweetalert2";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Contact from '../Contact'

function getSteps() {
    return ['Question 1', 'Question 2', 'Question 3', 'Question 4', 'Question 5'];
}

function Quiz2Ques(props) {

    const [state, setState] = React.useState({
        name: props.name,
        email: props.email,
        uid: props.uid,
        answer1: "",
        answer2: "",
        answer3: "",
        answer4: "",
        answer5: "",
        date: new Date().toLocaleString("en-IN"),
        score: "",
        maxscore: 5,
        comment: '',
        scores: ["", "", "", "", ""],
        quiz: "quiz2"
    })

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleText = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container>
                        <Typography>What is Boolean?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer1"
                                onChange={handleText} value={state.answer1} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container>
                        <Typography>Which one of the below is correct?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer2" value={state.answer2} onChange={handleText}>
                                    <FormControlLabel value="(Paneer) AND Roti AND (Coke OR Pepsi)" control={<Radio />} label="(Paneer) AND Roti AND (Coke OR Pepsi)" />
                                    <FormControlLabel value="Paneer AND Roti AND (Coke OR Pepsi)" control={<Radio />} label="Paneer AND Roti AND (Coke OR Pepsi)" />
                                    <FormControlLabel value="Paneer AND Roti AND Coke OR Pepsi" control={<Radio />} label="Paneer AND Roti AND Coke OR Pepsi" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 2:
                return (
                    <Grid container>
                        <Typography>Which one of the below is wrong?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer3" value={state.answer3} onChange={handleText}>
                                    <FormControlLabel value="A AND B AND C" control={<Radio />} label="A AND B AND C" />
                                    <FormControlLabel value="A AND B OR C" control={<Radio />} label="A AND B OR C" />
                                    <FormControlLabel value="A AND B AND (C OR C1)" control={<Radio />} label="A AND B AND (C OR C1)" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                )
            case 3:
                return (
                    <Grid container>
                        <Typography>What is nested loop? If i want to order Paneer & Roti or Biryani, how will be search look like?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer4"
                                onChange={handleText} value={state.answer4} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
            case 4:
                return (
                    <Grid container>
                        <Typography>When do you use ( ) ?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer5" value={state.answer5} onChange={handleText}>
                                    <FormControlLabel value="Whenever there is a choice between two - A OR B" control={<Radio />} label="Whenever there is a choice between two - A OR B" />
                                    <FormControlLabel value="Whenever you want two things for sure - A AND B" control={<Radio />} label="Whenever you want two things for sure - A AND B" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                )
        }
    }

    const handleSubmit = () => {

        if (props.fill === true) {
            fire
                .database()
                .ref("quiz2")
                .child(props.uid)
                .set(state)

            Swal.fire({
                icon: "success",
                title: "Awesome! You've completed Quiz-2",
            }).then(res => {
                props.rerenderParentCallback();
            })
        }
    };

    if (props.attempt)
        return (
            <div>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <div>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >Back</Button>
                                        {activeStep === steps.length - 1 ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >Finish</Button>
                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >Next/Skip</Button>
                                        }
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Contact />
            </div>
        )
    else
        handleSubmit()
}

export default Quiz2Ques