import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


export default class T1 extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card>
                <div className="Tmon">
                    <CardContent>
                        Its amazing platform from RecruitingMonk, you will get a lot to learn / to sharpen your recruiting skill. I urge all my friends in Recruitment industry to take up the Quiz.
                    </CardContent>
                </div>
                <div className="names">
                    <div>
                        <img src="/images/Arshad.jpeg" alt="Arshad" />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <h3>Arshad S.</h3>
                        <p style={{ color: 'blue' }}>[24]7.ai</p>
                    </div>
                </div>
            </Card>
        );
    }
}