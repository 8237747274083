import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

export default function Cards(props) {

    return (
        <Card className='programs-body'>
            <div align='center' className='img-div'>
                <img
                    className='programs-img'
                    src={props.im}
                />
            </div>
            <div align='center' className='text-div'>
                <div className='programs-heading'>{props.title}</div>
                <div className='programs-subheading'>{props.subtitle}</div>
            </div>
            <CardActions style={{ justifyContent: 'center' }}>
                <Button color="secondary" variant='contained'>Learn More</Button>
            </CardActions>
        </Card>
    );
}