import React, { Component } from 'react';
import { Dialog, DialogContent, Button, Grid } from '@material-ui/core';
import { signInWithGoogle, signInWithFb } from './config/fire'
import FacebookIcon from '@material-ui/icons/Facebook';

class Login extends Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false
        }

    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    componentDidMount() {

    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    render() {
        return (
            <div align="center" >
                <Button className="button-default" variant="contained" onClick={this.handleOpen}>
                    Login
                </Button>
                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="login-popup" fullWidth>
                    <DialogContent>
                        <Grid container className="login">
                            <Grid item xs={12}>
                                <p className="head">Login & Learn <b>Tech Recruiting</b></p>
                            </Grid>
                            <Grid item xs={12}>
                                <p></p>
                            </Grid>
                            <Grid item xs={12}>
                                <Button class="googleBtn" onClick={signInWithGoogle}>
                                    <img
                                        src="https://img.pngio.com/google-logo-png-circle-google-icon-png-white-transparent-google-logo-white-png-654_665.png"
                                        alt="logo"
                                    />Login With Google</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <p></p>
                            </Grid>
                            <Grid item xs={12}>
                                <Button class="fbBtn" onClick={signInWithFb}>
                                    <FacebookIcon /> Login With Facebook</Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default Login;